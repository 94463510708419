<template>

    <div
        class="ribbon float-left"
        style="margin-left: -10px; display: inline-block"
        :class="ribbonClassNames"
        >
            <i class="mdi mr-1" :class="iconClassNames"></i> {{ statusName.trans() }}
    </div>
</template>

<script setup>
import { computed } from 'vue'
import CardStatusEnum from '../../../js/frontend/helpers/card-status-enum';

const props = defineProps({
    status: {
        type: String,
        required: true
    }
})

const ribbonClassNames = computed(() => {
  return {
    'ribbon-success': props.status == CardStatusEnum.PUBLISHED,
    'ribbon-warning': props.status == CardStatusEnum.SAVED_AS_DRAFT,
    'ribbon-danger': props.status == CardStatusEnum.NEW_CHANGES,
  }
})

const iconClassNames = computed(() => {
  return {
    'mdi-check-all' : props.status == CardStatusEnum.PUBLISHED,
    'mdi-content-save-edit-outline' : props.status == CardStatusEnum.SAVED_AS_DRAFT,
    'mdi-bell-ring-outline' : props.status == CardStatusEnum.NEW_CHANGES,
  }
})

const statusName = computed(() => {
    return "box.status." + props.status
})
</script>