<template>
    {{ "catalog.box.status".trans() }}
    <span class="status-flag text-center d-inline-block ms-1" :class="['flag-' + status, 'flag-' + confirmed]">
        <span class="fw-bold fs-6 text-uppercase text-white">
            {{ ("box.status." + status + '.' + confirmed).trans() }}
        </span>
    </span>
</template>

<script setup>
    const props = defineProps({
        status: {
            type: String,
            required: true
        },
        confirmed: {
            type: String,
            required: true
        }
    })
</script>

<style lang="sass" scoped>
    .status-flag
        min-width: 120px
        border-radius: 4px
        padding-block: 2px
        padding-inline: 10px

        &.flag-draft
            &.flag-confirmed
                background-color: #C90505

            &.flag-not-confirmed
                background-color: #373A36
        
        &.flag-published
            &.flag-confirmed
                background-color: #20A244

            &.flag-not-confirmed
                background-color: #3986CE

</style>
